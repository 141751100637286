const store = {
  debug: false,
  state: {
    selectedMenuPath:'/',
    newsCategoryId:null
  },
  setSelectedMenuPath (newValue) {
    if (this.debug) console.log('setSelectedMenuPath triggered with', newValue)
    this.state.selectedMenuPath = newValue
  },
  setNewsCategoryId(newValue){
    this.state.newsCategoryId = newValue
  }
}

export default store